<!--
 * @Author: your name
 * @Date: 2021-04-27 16:12:22
 * @LastEditTime: 2021-04-27 17:35:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yaaami-frontend-user\src\components\NavBar.vue
-->

<template>
  <div class="nav-bar van-hairline--top">
    <van-tabbar route :active-color="primaryColor" inactive-color="#999">
      <van-tabbar-item replace to="/home" icon="wap-home">
        Home
      </van-tabbar-item>
      <van-tabbar-item replace to="/cart" :badge="count" icon="shopping-cart">
        Cart
      </van-tabbar-item>
      <van-tabbar-item replace to="/order" icon="todo-list">
        My orders
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine" icon="manager">
        Account
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '@/common/js/utils'

export default {
  setup() {
    const store = useStore()
    onMounted(() => {
      const userId = getLocal('userId')
      if (userId) {
       console.log('onMounted---userId---updateCart')
       // 调用的意义在哪？？？
       // return
        store.dispatch('updateCart')
      }
    })
    const count = computed(() => {
      return store.state.cartCount
    })

    return {
      count
    }
  }
}
</script>

<style lang="less" scoped>
    @import '../common/style/mixin';
    .nav-bar{
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      // padding: 5px 0;
      height: 50px;
      z-index: 1000;
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      .van-badge {
        background: @primary !important;
      }
      .nav-list {
        width: 100%;
        .fj();
        flex-direction: row;
        padding: 0;
        .nav-list-item {
          display: flex;
          flex: 1;
          flex-direction: column;
          text-align: center;
          color: #666;
          &.router-link-active {
            color: @primary;
          }
          i {
            text-align: center;
            font-size: 22px;
          }
          span{
            font-size: 12px;
          }
          .van-icon-shopping-cart-o {
            margin: 0 auto;
            margin-bottom: 2px;
          }
        }
      }
    }
</style>
