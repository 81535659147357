import { Notify } from 'vant'
import { addCart } from '@/service/cart'
import moment from 'moment'
import { parse, stringify } from 'qs'

export function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}
/**
* 处理url链接
*/
export const processUrl = () => {
  const url = window.location.href
  // 解决多次登录url添加重复的code与state问题
  const params = parse(url.split('?')[1])
  let redirectUrl = url
  if (params.code && params.state) {
    delete params.code
    delete params.state
    const query = stringify(params)
    if (query.length) {
      redirectUrl = `${url.split('?')[0]}?${query}`
    } else {
      redirectUrl = `${url.split('?')[0]}`
    }
  }
  return redirectUrl
}

export const getLocal = (name) => {
  return localStorage.getItem(name)
}

export const setLocal = (name, value) => {
  localStorage.setItem(name, value)
}

// 图片前缀方法
export const prefix = (url) => {
  if (url && url.startsWith('http')) {
    return url
  } else {
    url = `http://backend-api-01.newbee.ltd${url}`
    return url
  }
}

export const tempAdd = (time, storage) => {
  time = time + ' 00:00:00'
  var timestamp = Date.parse(new Date())
  var sourcetime = Date.parse(time)
  if (timestamp > sourcetime || storage <= 0) {
    Notify({ message: 'The product is not in stock or cannot be delivered', color: '#ad0000', background: '#ffe1e1' })
    return false
  } else {
    return true
  }
}

export const toWeek = (date) => {
  const datelist = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return datelist[new Date(date).getDay()]
}

// 添加和编辑本地购物车
export const setLocalCart = (goods_id, count, stock_id, sale_time) => {
  console.log('sale_time 我用了',sale_time)
  // 不再限制 同一天的 商品添加购物车了
  /*var localTime = sessionStorage.getItem('localCartTime')
  if (localTime && sale_time !== localTime) {
    return false
  }*/
  var localCartObj = sessionStorage.getItem('localCartObj') ? JSON.parse(sessionStorage.getItem('localCartObj')) : {}
  var localCartStock = sessionStorage.getItem('localStockList') ? JSON.parse(sessionStorage.getItem('localStockList')) : {}
  localCartObj[goods_id] = count
  localCartStock[stock_id] = count
  sessionStorage.setItem('localCartObj', JSON.stringify(localCartObj))
  // sessionStorage.setItem('localCartTime', sale_time)
  sessionStorage.setItem('localStockList', JSON.stringify(localCartStock))
  return true
}
export const delLocalCart = (goods_id, stock_id) => {
  var localCartObj = sessionStorage.getItem('localCartObj') ? JSON.parse(sessionStorage.getItem('localCartObj')) : {}
  var localCartStock = sessionStorage.getItem('localStockList') ? JSON.parse(sessionStorage.getItem('localStockList')) : {}
  delete localCartObj[goods_id]
  delete localCartStock[stock_id]
  var arr = Object.keys(localCartObj)
  if (arr.length === 0) {
    sessionStorage.setItem('localCartTime', '')
  }
  sessionStorage.setItem('localCartObj', JSON.stringify(localCartObj))
  sessionStorage.setItem('localStockList', JSON.stringify(localCartStock))
}
// 将购物车的数据更新到服务器
export const handleLocalCart = async() => {
  var localCartStock = sessionStorage.getItem('localStockList') ? JSON.parse(sessionStorage.getItem('localStockList')) : {}
  var localTime = sessionStorage.getItem('localCartTime')
  if (localTime) {
    var sessionTime = moment(localTime).unix()
    var tmp = Date.parse(new Date()).toString()
    tmp = Number(tmp.substr(0, 10))
    if (sessionTime > tmp && localCartStock) {
      const localMap = []
      for (var i in localCartStock) {
        localMap.push({
          stock_id: Number(i),
          count: Number(localCartStock[i])
        })
      }
      const promise = localMap.map((item, i) => {
        return addCartFun(item.stock_id, item.count)
      })
      Promise.all(promise)
        .then(allData => {
          sessionStorage.setItem('localCartObj', '')
          sessionStorage.setItem('localStockList', '')
          sessionStorage.setItem('localCartTime', '')
        }).catch(() => {})
    }
  }
}
export const addCartFun = async(stock_id, count) => {
  await addCart({
    stock_id: stock_id,
    count: count
  })
}
