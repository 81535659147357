/*
 * @Author: your name
 * @Date: 2021-04-21 10:33:06
 * @LastEditTime: 2021-04-21 10:39:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\service\user.js
 */

import axios from '../utils/axios'

// 获取banner接口
export function getBanner(params) {
  return axios.get('/api/h/banner/get', { params })
}
// 获取通知列表
export function getNotice(params) {
  return axios.get('/api/h/notice/get', { params })
}
// 获取商品列表
export function getGoodsList(params) {
  // return axios.get('/api/h/goods/get',{ params });
  return axios.get('/api/h/all', { params })
}
// 获取商品列表
// 获取最新订单列表
export function getNewOrderList(params) {
  return axios.get('/api/h/order/new', { params })
}
export function goodsDetail(params) {
  return axios.get('/api/product/list', { params })
}
export function getTag(params) {
  return axios.get('/api/tag/product', { params })
}
export function searchTag(params) {
  return axios.get('/api/tag/get', { params })
}
export function getMoreGoodsDetail(params) {
  return axios.get('/api/product/order_batch', { params })
}
// 获取商品送货时间
export function productTime(params) {
  return axios.get('/api/product/time', { params })
}
export function saleDate(params) {
  return axios.get('/api/h/sale/time', { params })
}
export function otherCost(params) {
  return axios.get('/api/order/code', { params })
}
// 获取商品评论列表
export function evaluateList(params) {
  return axios.get('/api/order_product_appraise/appraise_list_product', { params })
}
// 评论的评论
export function evaluateReply(params) {
  return axios.post('/api/order_product_appraise/appraise_apply', params)
}
// 获取评论回复列表
export function getReplyList(params) {
  return axios.get('/api/order_product_appraise/appraise_apply_list', { params })
}
// 删除评论
export function DeleteReply(params) {
  return axios.get('/api/order_product_appraise/appraise_apply_del', { params })
}
// 获取品类列表
export function categoryList(params) {
  return axios.get('/api/category/list', { params })
}
export function getMoreGoodsList(params) {
  return axios.get('/api/product/batch', { params })
}
export function getMoreGoodsListNew(params) {
  return axios.post('/api/product/batch/v3', params)
}
// 获取品类商品信息
export function cateProductList(params) {
  return axios.get('/api/product/v2/batch', { params })
}
